import { ReactElement, useEffect, useState } from 'react'

const kebabToCamel = (kebab: string): string => {
  const words = kebab.split('-')
  const [first, ...rest] = words

  const camel = [
    first,
    ...rest.map((word) =>
      word.length ? word[0].toUpperCase() + word.slice(1) : '',
    ),
  ].join('')

  return camel
}

const camelToKebab = (camel: string): string => {
  const words = camel.split(/(?=[A-Z])/)
  const kebab = words.map((word) => word.toLowerCase()).join('-')

  return kebab
}

const cssToInline = (css: string): string => {
  css = css.replace(/\/\*[\s\S]*?\*\//g, '')

  const lines = css
    .split(';')
    .map((line) => line.trim())
    .filter((line) => line)

  const mappedLines = lines.map((line) => {
    const [key, value] = line.split(':').map((part) => part.trim())

    const processedValue = `'${value}'`

    return `${kebabToCamel(key)}: ${processedValue},`
  })

  return mappedLines.join('\n')
}

const inlineToCss = (inline: string): string => {
  inline = inline.replace(/\/\*[\s\S]*?\*\//g, '')

  const lines = inline
    .split(',')
    .map((line) => line.trim())
    .filter((line) => line)

  const mappedLines = lines.map((line) => {
    const [key, value] = line.split(':').map((part) => part.trim())
    const [, processedValue] = /'(.*?)'/.exec(value) ?? []

    return `${camelToKebab(key)}: ${processedValue};`
  })

  return mappedLines.join('\n')
}

const App = (): ReactElement => {
  const [css, setCss] = useState('')
  const [inline, setInline] = useState('')

  const updateCss = (css: string): void => {
    setCss(css)
    setInline(cssToInline(css))
  }

  const updateInline = (inline: string): void => {
    setInline(inline)
    setCss(inlineToCss(inline))
  }

  useEffect(() => {
    updateCss(`color: #406ADC;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;`)
  }, [])

  return (
    <div className="flex flex-row h-full">
      <div className="flex flex-col flex-1 p-2">
        <span className="font-bold">CSS</span>
        <textarea
          className="w-full flex-1 border p-1 font-mono text-xs"
          value={css}
          onChange={(e) => updateCss(e.target.value)}
        />
      </div>
      <div className="flex flex-col flex-1 p-2">
        <span className="font-bold">Inline React</span>
        <textarea
          className="w-full flex-1 border p-1 font-mono text-xs"
          value={inline}
          onChange={(e) => updateInline(e.target.value)}
        />
      </div>
    </div>
  )
}

export default App
